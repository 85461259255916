import React from 'react';
import { DemoTemplate } from 'src/templates';

import { Demo } from 'src/components/Demo';

const metaData = {
  layout: 'demo',
  'meta-title': 'Plaid Demo',
  'meta-description':
    'Explore how Plaid Link helps 100+ million people to securely connect their financial accounts to thousands of apps.',
};

export default () => {
  return (
    <DemoTemplate {...metaData} cmsTheme='web 3.0'>
      <Demo />
    </DemoTemplate>
  );
};
